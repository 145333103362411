import React, { useEffect, useState, useMemo, useContext } from "react";
import { IonPage, IonContent, IonInfiniteScroll } from "@ionic/react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { useHistory } from "react-router";
import firebase, { flamelinkApp as flamelink, db } from "../../firebase";

import { AuthContext } from "../../Providers/UserProvider";
import LoaderWithoutImage from "../../components/LoaderWithoutImage";
import { showError } from "../../Services/ui";
import Modal from "../../components/Modal";

interface Answer {
  answerOption: string;
  isCorrectAnswer: boolean;
  uniqueKey: string;
}

interface Question {
  examQuestion: string;
  multipleCorrectAnswers: boolean;
  answers: Answer[];
  uniqueKey: string;
}

interface IFinalExam {
  id: string;
  examTitle: string;
  finalExam: Question[];
  timeLimit: number;
}

const FinalExam = ({ match }: { match: any }) => {
  const { studentId } = useContext(AuthContext);

  const courseId = useMemo(
    () => (match.params ? match.params.courseId : null),
    [match]
  );

  const history = useHistory();
  const [finalExam, setFinalExam] = useState({} as IFinalExam);
  const [startTime, setStartTime] = useState(null as number | null);
  const [timeLimit, setTimeLimit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);
  const [gradingExam, setGradingExam] = useState(false);

  // map question key to object with selected answer info
  const [selectedAnswers, setSelectedAnswers] = useState(
    {} as Record<string, null | string | Record<string, boolean>>
  );

  useEffect(() => {
    const getStudentExam = async () => {
      const exam = await db
        .doc(`/students/${studentId}/courses/${courseId}/exams/exam`)
        .get();
      if (!exam.exists) {
        let answerMap = {} as Record<
          string,
          null | string | Record<string, boolean>
        >;
        finalExam.finalExam?.map((question: Question) => {
          answerMap[question.uniqueKey] = question.multipleCorrectAnswers
            ? {}
            : null;
          return null;
        });
        const startTimestamp = new Date().getTime();

        await db
          .doc(`/students/${studentId}/courses/${courseId}/exams/exam`)
          .set({
            startTime: startTimestamp,
            selectedAnswers: answerMap,
            timeLimit: finalExam.timeLimit,
          });
        setStartTime(startTimestamp);
        setSelectedAnswers(answerMap);
      } else {
        if (checkExamExpired(exam.data()?.startTime, exam.data()?.timeLimit)) {
          setExpired(true);
        }
        setSelectedAnswers({ ...exam.data()?.selectedAnswers });
        setStartTime(exam.data()?.startTime);
      }
    };
    if (!isEmpty(finalExam)) {
      getStudentExam();
    }
  }, [courseId, studentId, finalExam]);

  useEffect(() => {
    const getFinal = async () => {
      setLoading(true);
      let course: any = await db
        .doc(`/students/${studentId}/courses/${courseId}`)
        .get();
      course = course.data();
      if (
        !course ||
        course.numberContentsCompleted !== course.numberContentsInCourse
      ) {
        setLoading(false);
        history.push("/exam/error/unauthorized");
        return;
      }
      const courseRef = db.doc(`/fl_content/${courseId}`);
      let final = await flamelink.content.get({
        schemaKey: "finalExam",
        fields: ["id", "finalExam", "examTitle", "course", "timeLimit"],
        filters: [["course", "==", courseRef]],
        populate: true,
      });
      final = Object.values(final ?? {})?.[0];
      setFinalExam(final as IFinalExam);
      setTimeLimit(final?.timeLimit);
      setLoading(false);
    };
    getFinal();
  }, [courseId, studentId, history]);

  const checkExamExpired = (startTime: number, timeLimit: number) => {
    // timeLimit is in minutes
    const timeLimitMs = timeLimit * 60 * 1000;
    if (new Date().getTime() > startTime + timeLimitMs) {
      return true;
    }
    return false;
  };

  const [timeLeftSeconds, setTimeLeftSeconds] = useState(null as null | number);

  useEffect(() => {
    if (!timeLimit || !startTime) {
      return;
    }
    const timeLimitMs = timeLimit * 60 * 1000;
    setTimeLeftSeconds(
      Math.floor((startTime + timeLimitMs - new Date().getTime()) / 1000)
    );
  }, [timeLimit, startTime]);

  useEffect(() => {
    const expiredInterval = setInterval(() => {
      if (!timeLimit || !startTime) {
        return;
      }
      const timeLimitMs = timeLimit * 60 * 1000;
      const timeLeftMs = startTime + timeLimitMs - new Date().getTime();
      setTimeLeftSeconds(Math.floor(timeLeftMs / 1000));
      if (checkExamExpired(startTime, timeLimit)) {
        setExpired(true);
        clearInterval(expiredInterval);
        console.log("Exam expired!");
      }
    }, 1000);
    return () => clearInterval(expiredInterval);
  }, [startTime, timeLimit]);

  const gradeExam = async () => {
    const gradeStudentExam = firebase.functions().httpsCallable("gradeExam");
    await gradeStudentExam({
      studentId,
      courseId,
      studentExamData: selectedAnswers,
    });
  };

  const saveAnswer = (
    questionKey: string,
    answerKey: string,
    allowMultiple: boolean
  ) => {
    if (startTime && timeLimit && checkExamExpired(startTime, timeLimit)) {
      showError("Exam expired!");
    }
    if (!startTime || !timeLimit || checkExamExpired(startTime, timeLimit)) {
      return;
    }
    if (!allowMultiple) {
      setSelectedAnswers({
        ...selectedAnswers,
        [questionKey]: answerKey,
      });
      db.doc(`/students/${studentId}/courses/${courseId}/exams/exam`).update({
        selectedAnswers: {
          ...selectedAnswers,
          [questionKey]: answerKey,
        },
      });
      return;
    }
    let currentAnswers: null | Record<string, boolean> = selectedAnswers[
      questionKey
    ] as Record<string, boolean> | null;
    if (currentAnswers === null) {
      currentAnswers = { [answerKey]: true };
    } else if (currentAnswers[answerKey] === undefined) {
      currentAnswers = { ...currentAnswers, [answerKey]: true };
    } else {
      currentAnswers = omit(currentAnswers, answerKey);
    }
    setSelectedAnswers({
      ...selectedAnswers,
      [questionKey]: currentAnswers,
    });
    db.doc(`/students/${studentId}/courses/${courseId}/exams/exam`).update({
      selectedAnswers: {
        ...selectedAnswers,
        [questionKey]: currentAnswers,
      },
    });
  };

  const formatTime = (time: number) => {
    // time is in seconds here
    const hours = Math.floor(time / (60 * 60));
    const minutes = Math.floor((time - hours * 60 * 60) / 60);
    const seconds = time % 60;

    const pad = (value: number) => {
      return value.toString().padStart(2, "0");
    };

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  };

  const isCompleted = useMemo(() => {
    return (
      Object.keys(selectedAnswers ?? {})?.filter((answer: string) => {
        return !selectedAnswers[answer] || isEmpty(selectedAnswers[answer]);
      }).length === 0
    );
  }, [selectedAnswers]);

  const Question = (question: Question) => {
    return (
      <div>
        {question.answers?.map((answer: Answer) => {
          const selectedAnswer = selectedAnswers[question.uniqueKey] as any;
          const isSelected = question.multipleCorrectAnswers
            ? selectedAnswer?.[answer.uniqueKey] === true
            : selectedAnswer === answer.uniqueKey;
          return (
            <div className="py-2 md:text-center" key={answer.uniqueKey}>
              <button
                onClick={() => {
                  saveAnswer(
                    question.uniqueKey,
                    answer.uniqueKey,
                    question.multipleCorrectAnswers
                  );
                }}
                className="w-full md:max-w-lg md:mx-auto cursor-pointer"
              >
                <div
                  className={`px-2 mx-2 rounded-md text-center border-2 ${
                    isSelected
                      ? "border-primary ring-inset ring-1 ring-primary"
                      : "border-gray-500"
                  }`}
                >
                  <p className="text-lg">{answer.answerOption}</p>
                </div>
              </button>
              <div className="text-center">
                {question.multipleCorrectAnswers ? (
                  <input
                    type="checkbox"
                    checked={isSelected}
                    className="cursor-pointer"
                    onChange={() => {
                      saveAnswer(
                        question.uniqueKey,
                        answer.uniqueKey,
                        question.multipleCorrectAnswers
                      );
                    }}
                  />
                ) : (
                  <input
                    type="radio"
                    checked={isSelected}
                    className="cursor-pointer"
                    onChange={() => {
                      saveAnswer(
                        question.uniqueKey,
                        answer.uniqueKey,
                        question.multipleCorrectAnswers
                      );
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const getTimerColors = (timeLeft: number) => {
    // time left in seconds
    if (timeLeft < 60 * 2) {
      return "bg-error";
    } else if (timeLeft < 60 * 10) {
      return "bg-yellow-500";
    }
    return "bg-success";
  };

  if (loading || isEmpty(finalExam)) {
    return <LoaderWithoutImage message="Loading exam..." />;
  }

  if (expired) {
    history.push("/exam/error/expired");
    return null;
  }

  return (
    <IonPage>
      <IonContent>
        <IonInfiniteScroll className="bg-white text-black">
          <div className="bg-primary py-4 w-screen flex flex-row justify-center align-middle fixed top-0">
            <div className="flex flex-row w-full">
              <div className="flex flex-1 flex-grow"></div>
              <div className="flex flex-1 flex-grow justify-center items-center">
                <h2 className="text-white font-medium text-center text-xl md:text-2xl self-center">
                  {finalExam.examTitle}
                </h2>
              </div>
              <div className="flex flex-1 flex-grow justify-end pr-3 items-center">
                {timeLeftSeconds !== null && (
                  <div
                    className={`rounded-xl px-3 py-1 mr-3 ${getTimerColors(
                      timeLeftSeconds
                    )}`}
                  >
                    <p className="text-white font-bold">
                      {formatTime(timeLeftSeconds)}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="container mx-auto max-w-6xl mt-10 py-8 px-2 md:px-6">
            {finalExam?.finalExam?.map((question: Question, index: number) => {
              return (
                <div key={question.uniqueKey} className="mb-4">
                  <div className="flex flex-row mb-2">
                    <h3 className="text-left text-lg md:text-xl font-medium mr-2 md:mr-4">
                      {index + 1}.
                    </h3>
                    <h3 className="text-left md:text-center text-lg md:text-xl font-medium">
                      {question.examQuestion}
                    </h3>
                  </div>
                  {Question(question)}
                </div>
              );
            })}
            <div className="text-center">
              <button
                disabled={!isCompleted || gradingExam}
                onClick={() => {
                  setGradingExam(true);
                  gradeExam().then(() => {
                    history.push("/home");
                  });
                }}
                className={`shadow-md font-bold hover:cursor-pointer px-6 py-2 rounded-md text-white ${
                  !isCompleted || gradingExam
                    ? "bg-gray-500 opacity-50"
                    : "bg-primary hover:bg-primary-dark"
                }`}
              >
                Submit
              </button>
            </div>
          </div>
          {gradingExam && (
            <Modal
              showHeader={false}
              children={
                <div className="text-center p-4">
                  <h2 className="text-xl font-bold tracking-wide pb-2">
                    Grading exam
                  </h2>
                  <p>
                    Sit tight while we grade your answers! You'll be redirected
                    momentarily.
                  </p>
                </div>
              }
            />
          )}
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

FinalExam.propTypes = {
  match: PropTypes.object.isRequired,
};
export default FinalExam;
