import Swal from "sweetalert2";

export const getConfirmation = async (
  title: string,
  text: string,
  { cancelButtonText = "", confirmButtonText = "" }
) => {
  const confirmed = await Swal.fire({
    title,
    text,
    allowEscapeKey: true,
    showCancelButton: true,
    showConfirmButton: true,
    ...(cancelButtonText && { cancelButtonText }),
    ...(confirmButtonText && { confirmButtonText }),
  });
  return confirmed && confirmed.value;
};

export const showError = (err: any, title = "Error with request") => {
  if (typeof err === "string") {
    return (error: any) => showError(error, err);
  }
  
  return Swal.fire({
    title,
    text: err.message,
    icon: "error",
    showCancelButton: false,
    showConfirmButton: false,
    showCloseButton: true,
  });
};
