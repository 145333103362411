import React from "react";
import { default as _ReactPlayer } from "react-player/lazy";
import { ReactPlayerProps } from "react-player/types/lib";
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

interface VideoContentProps {
  url: string;
  setIsContentCompleted: any;
}

const VideoContent: React.FC<VideoContentProps> = ({
  url,
  setIsContentCompleted,
}) => {
  return (
    <div>
      <ReactPlayer
        url={url}
        width="100%"
        controls={true}
        onEnded={() => setIsContentCompleted(true)}
      />
    </div>
  );
};

export default VideoContent;
