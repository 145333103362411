import React, { useState, useEffect } from "react";
import ContentCollection from "./ContentCollection";
import { IonIcon } from "@ionic/react";
import { arrowDown } from "ionicons/icons";
import isEmpty from "lodash/isEmpty";

interface SectionCollectionProps {
  contents: any[];
  title: string;
  isSectionActive: boolean;
}

const SectionCollection: React.FC<SectionCollectionProps> = ({
  contents,
  title,
  isSectionActive,
}) => {
  const [isActive, setIsActive] = useState(isSectionActive);

  useEffect(() => {
    setIsActive(isSectionActive);
  }, [isSectionActive]);

  return (
    <div>
      <button
        onClick={() => {
          setIsActive(!isActive);
        }}
        type="button"
        className="w-full"
      >
        <div className="bg-gray-100 p-4 pb-2 focus:outline-none">
          <div className=" flex flex-row justify-between">
            <div className="text-left">
              <h2 className="text-md text-gray-800">{title}</h2>
            </div>
            {!isEmpty(contents) && (
              <div className="flex-none w-min text-right">
                <IonIcon
                  icon={arrowDown}
                  className={`bi bi-arrow-down text-black transform transition duration-300 ease-in-out ${
                    isActive ? "rotate-180" : ""
                  }`}
                />
              </div>
            )}
          </div>
        </div>
      </button>
      {isActive &&
        contents.map((contentCol) => (
          <ContentCollection {...contentCol} key={contentCol.id} />
        ))}
    </div>
  );
};

export default SectionCollection;
