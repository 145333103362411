import { useMemo } from "react";
import { IonRouterLink } from "@ionic/react";
import PropTypes from "prop-types";

const ExamError = ({ match }: { match: any }) => {
  const error = useMemo(
    () => (match.params ? match.params.errorName : null),
    [match]
  );

  const unauthorizedMsg =
    "You can't take this exam until you've finished all of its contents. Head back to the course and finish it up before trying to take the exam!";

  const expiredMsg =
    "You're out of time for this exam. Head back to the homepage to view your course progress.";

  return (
    <div className="flex flex-col h-3/4 container mx-auto">
      <div className="text-center my-auto">
        <h1 className="text-4xl font-semibold mt-2 mb-2">
          {error === "expired" ? "Exam expired" : "Oops!"}
        </h1>
        <p>{error === "expired" ? expiredMsg : unauthorizedMsg}</p>
        <div className="mt-6">
          <IonRouterLink href="/home">
            <button className="bg-primary hover:bg-primary-dark rounded-md shadow-md text-white px-3 py-2">
              Back to home
            </button>
          </IonRouterLink>
        </div>
      </div>
    </div>
  );
};

ExamError.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ExamError;
