import React, { useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import Home from "../../pages/Home";
import Login from "../../pages/Login";
import Content from "../../pages/Content";
import Signup from "../../pages/Signup";
import ForgotPassword from "../../pages/ForgotPassword";
import FinalExam from "../../pages/FinalExam";
import ExamError from "../../pages/ExamError";
import FinalExamStaging from "../../pages/FinalExamStaging";
import { AuthContext } from "../../Providers/UserProvider";
import Loader from "../../components/Loader";
import SuccessPage from "../../pages/Success";

const Router = () => {
  const { studentId, user, initialized } = useContext(AuthContext);

  return (
    <div>
      {initialized ? (
        <>
          <BrowserRouter>
            <CompatRouter>
              {studentId && user ? (
                <>
                  <Switch>
                    <Route path="/home" component={Home} />
                    <Route
                      path="/content/:courseId/:contentId"
                      component={Content}
                    />
                    <Route
                      path="/exam/error/:errorName"
                      component={ExamError}
                    />
                    <Route path="/exam/:courseId" component={FinalExam} />
                    <Route
                      path="/exam-staging/:courseId"
                      component={FinalExamStaging}
                    />
                    <Route path="/success" component={SuccessPage} />
                    <Redirect to="/home" />
                  </Switch>
                </>
              ) : (
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Route path="/signup" component={Signup} />
                  <Route path="/success" component={SuccessPage} />
                  <Redirect to="/login" />
                </Switch>
              )}
            </CompatRouter>
          </BrowserRouter>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default Router;
