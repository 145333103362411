import React, { createContext, useContext, useEffect, useState } from "react";
import { functions, db } from "../firebase";
import { AuthContext } from "./UserProvider";

const defaultCourseContext: {
  loading: boolean;
  courses: Array<{
    id: string;
    title: string;
    description: string;
    aggregateTimeLogged: number;
    currentContentId?: string;
    currentContentTitle?: string;
    finalExamPassed: boolean | null;
  }>;
} = {
  courses: [],
  loading: false,
};

const CourseContext = createContext(defaultCourseContext);

const CourseProvider: React.FC<{}> = ({ children }) => {
  const [courses, setCourses] = useState([] as any);
  const [loading, setLoading] = useState(false);

  const { studentId } = useContext(AuthContext);

  useEffect(() => {
    if (!studentId) {
      setCourses([]);
      return;
    }
    setLoading(true);
    return db
      .collection(`students/${studentId}/courses`)
      .onSnapshot(async (querySnapshot) => {
        const courseList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCourses(
          courseList.map((course: any) => ({
            isLoading: !course.currentContentId,
            ...course,
          }))
        );
        setLoading(false);
      });
  }, [studentId]);

  useEffect(() => {
    if (studentId) {
      const setCurrentContent = functions.httpsCallable(
        "setStudentCourseCurrentContent"
      );

      const loadAndFetchCourseCurrentContents = async () => {
        await Promise.all(
          courses
            .filter((course: any) => !course.currentContentId)
            .map(async (course: any) => {
              await setCurrentContent({
                studentId,
                courseId: course.id,
              });
            })
        );
      };

      loadAndFetchCourseCurrentContents();
    }
  }, [courses, studentId]);

  return (
    <CourseContext.Provider
      value={{
        courses,
        loading,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

const useCourseContext = () => {
  return useContext(CourseContext);
};

export { useCourseContext, CourseContext };
export default CourseProvider;
