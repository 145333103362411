import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import LoaderWithoutImage from "../../components/LoaderWithoutImage";
import PrimaryButton from "../../components/PrimaryButton";
import { auth } from "../../firebase";
import { useCourseContext } from "../../Providers/CourseProvider";
import CourseListing from "./CourseListing";
import { RouteComponentProps } from "react-router";
import RedeemTokenComponent from "./RedeemTokenComponent";

const Home: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const { loading, courses } = useCourseContext();
  if (loading) {
    return <LoaderWithoutImage />;
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="flex flex-col h-full bg-gray-100">
          <div className="text-center p-4 bg-gradient-to-r from-primary via-blue-700 to-primary shadow-lg">
            <h2 className="text-2xl text-white">Courses</h2>
          </div>
          <div className="flex flex-nowrap overflow-x-auto overflow-y-hidden py-5">
            {courses.map((course: any) => (
              <CourseListing
                onOpenCourse={() => {
                  course.currentContentId &&
                    history.push(
                      `/content/${course.id}/${course.currentContentId}`
                    );
                }}
                onOpenExam={() => {
                  history.push(`/exam-staging/${course.id}`);
                }}
                {...course}
                key={course.id}
              />
            ))}
          </div>
          <div className="text-center mt-2 p-4">
            <RedeemTokenComponent />
          </div>
          <div className="text-center mt-auto p-4">
            <PrimaryButton
              text="Log out"
              bgColor="bg-error"
              onClick={() => {
                auth.signOut();
              }}
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
