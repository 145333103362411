import React, { useState } from "react";
import { showError } from "../../../Services/ui";
import { functions, auth } from "../../../firebase";
import FullButton from "../../../components/FullButton";

interface SignupFormProps {
  setLoading: any;
}

const SignupForm: React.FC<SignupFormProps> = ({ setLoading }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [code, setCode] = useState("");

  const signUp = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      const register = functions.httpsCallable("registerStudent");
      await register({
        email,
        password,
        firstName,
        lastName,
        code,
      });

      await auth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      showError(e, "Error signing up");
      setLoading(false);
    }
  };

  return (
    <div className="shadow-lg rounded-lg bg-white">
      <form onSubmit={signUp}>
        <div className="p-3 md:p-6">
          <h2 className="text-3xl text-black">Welcome to Food Academy</h2>
          <div className="mt-5">
            <div className="text-left mb-2 w-full">
              <label htmlFor="firstName" className="text-base text-black">
                First name
                <input
                  type="text"
                  id="firstName"
                  value={firstName}
                  name="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                  className="rounded-md border-2 border-gray-200 px-1 mt-1 py-1 md:px-2 bg-white text-black focus:outline-none focus:border-blue-500 transition-colors w-full"
                  required
                />
              </label>
            </div>
            <div className="text-left my-2 w-full">
              <label htmlFor="lastName" className="text-base text-black">
                Last name
                <input
                  type="text"
                  id="lastName"
                  value={lastName}
                  name="lastName"
                  onChange={(e) => setLastName(e.target.value)}
                  className="rounded-md border-2 border-gray-200 px-1 mt-1 py-1 md:px-2 bg-white text-black focus:outline-none focus:border-blue-500 transition-colors w-full"
                  required
                />
              </label>
            </div>
          </div>
          <div className="text-left my-2 w-full">
            <label htmlFor="email" className="text-base text-black">
              Email
              <input
                type="text"
                id="email"
                value={email}
                name="email"
                autoComplete="username"
                onChange={(e) => setEmail(e.target.value)}
                className="block rounded-md border-2 border-gray-200 mt-1 px-1 py-1 md:px-2 bg-white text-black focus:outline-none focus:border-blue-500 transition-colors w-full"
                required
              />
            </label>
          </div>
          <div className="text-left mt-2 mb-6">
            <label htmlFor="password" className="text-base text-black">
              Password
              <input
                type="password"
                id="password"
                value={password}
                name="username"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value!)}
                className="block rounded-md border-2 border-gray-200 mt-1 px-1 py-1 md:px-2 bg-white text-black focus:outline-none focus:border-blue-500 transition-colors w-full"
                required
              />
            </label>
          </div>
          <div className="text-left mt-2 mb-6">
            <label htmlFor="accessCode" className="text-base text-black">
              Access Code
              <input
                type="text"
                id="accessCode"
                value={code}
                name="username"
                onChange={(e) => setCode(e.target.value!)}
                className="block rounded-md border-2 border-gray-200 mt-1 px-1 py-1 md:px-2 bg-white text-black focus:outline-none focus:border-blue-500 transition-colors w-full"
                required
              />
            </label>
          </div>
        </div>
        <div className="text-center">
          <FullButton text="Sign up" buttonType="submit" />
        </div>
      </form>
    </div>
  );
};

export default SignupForm;
