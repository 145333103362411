import React, { useState } from "react";
import {
  IonRouterLink,
  IonPage,
  IonImg,
  IonInfiniteScroll,
  IonContent,
} from "@ionic/react";
import SignupForm from "./SignupForm";
import SecondaryButton from "../../components/SecondaryButton";
import logo from "../../assets/logo.png";
import Loader from "../../components/Loader";

const Signup: React.FC = () => {
  const [loading, setLoading] = useState(false);

  if (loading) {
    return <Loader />;
  }

  return (
    <IonPage>
      <IonContent>
        <IonInfiniteScroll className="bg-cover min-h-screen bg-center bg-no-repeat bg-hero-bg">
          <div className="text-right p-4">
            <IonRouterLink href="/login">
              <SecondaryButton text="Log in" textColor="text-success" />
            </IonRouterLink>
          </div>
          <div className="max-w-xs mx-auto my-3">
            <IonImg src={logo} />
          </div>
          <div className="mx-auto mb-auto text-center p-4 pt-8 container md:max-w-1/2">
            <SignupForm setLoading={setLoading} />
          </div>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default Signup;
