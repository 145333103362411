interface PrimaryButtonProps {
  text: string;
  bgColor?: string;
  onClick?: () => void;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  text,
  bgColor,
  onClick = () => {},
}) => {
  return (
    <button
      type="button"
      className={`rounded-md text-white font-bold px-4 py-2 text-lg w-36 uppercase shadow-md ${
        bgColor ?? "bg-primary"
      }`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default PrimaryButton;
