import { Link } from "react-router-dom";

export type CourseType =
  | "GMPs in Food Manufacturing"
  | "Sanitation Standard Operating Procedures"
  | "Procedimientos Operativos Estándar de Saneamiento (SSOPs)"
  | "GMPs (BPF) en la Fabricación de Alimentos";

const courseIntroMap: Record<CourseType, string> = {
  "GMPs in Food Manufacturing": "Next up: 117.0 Course Intro.",
  "Sanitation Standard Operating Procedures": "Next up: 120.6 Course Intro",
  "GMPs (BPF) en la Fabricación de Alimentos": "Introducción al curso 117.0.",
  "Procedimientos Operativos Estándar de Saneamiento (SSOPs)":
    "Introducción al curso 120.6.",
};

const TRANSLATIONS = {
  WELCOME: {
    english: "Welcome",
    spanish: "Bienvenido",
  },
  INTRO: {
    english:
      "Please follow the instructions below. Upon completion, you will receive a Certificate of Course Completion and Badge Credentials. When creating your Food Smart Strategies Academy Account, please note that the email you sign up with will be the one connected to your badge and certificate of completion.",
    spanish:
      "Favor de seguir las siguientes instrucciones. Al finalizar, recibirá un Certificado del curso y credenciales de insignia. Al crear su cuenta de Food Smart Strategies Academy, tenga en cuenta que el correo electrónico con el que se registre será el que esté conectado a su insignia y certificado de finalización",
  },
  STEP_1: {
    english: "STEP 1: Website: ",
    spanish: "PASO 1: Sitio web: ",
  },
  STEP_2: {
    english: "STEP 2: Complete account information",
    spanish: "PASO 2: Agregar la información de la cuenta",
  },
  STEP_3: {
    english: "STEP 3: Input Access Code",
    spanish: "PASO 3: Ingrese el código de acceso",
  },
  STEP_4: {
    english: "STEP 4: Click on sign-up",
    spanish: "PASO 4: Seleccione el botón de registro",
  },

  MAIN_PAGE_INSTRUCTIONS_BOTH: {
    english:
      'This will take you to the main page. On the upper left corner, you will see both modules, "GMPs in Food Manufacturing" and "Sanitation Standard Operating Procedures." Click on "Next up” to start the courses.',
    spanish:
      'Al completar el registro, esto lo llevará a la página principal. En la esquina del lado izquierdo, verá ambos cursos: "Buenas Prácticas de Manufactura (BPM/GMP’s)" y "Procedimientos Operativos Estándares de Saneamiento." Seleccione, “Siguiente Título” para iniciar los cursos.',
  },

  MAIN_PAGE_INSTRUCTIONS_ONE: {
    english:
      "This will take you to the main page. On the upper left corner, you will see 'COURSE_NAME' Click on 'FIRST_SECTION' to start the course.",
    spanish:
      "Al completar el registro, esto lo llevará a la página principal donde va a seleccionar en la parte izquierda debajo de: 'COURSE_NAME' Seleccione, 'FIRST_SECTION' Esto iniciará el curso.",
  },

  IMAGE_SLIDER_INSTRUCTIONS: {
    english:
      "If you are on a laptop, you will have to click on the image and drag  it to the left to go onto the next slide or you can also use the  cursor arrow keys.  If you are on a touchscreen device, you can just  swipe left.",
    spanish:
      "Si está en una computadora portátil, deberá seleccionar en la imagen y deslizarla hacia la izquierda para pasar a la siguiente imagen o también puede usar las teclas de flecha del cursor . En cualquier aparato que tenga pantalla táctil, puedes simplemente deslizarte hacia la izquierda.",
  },

  LAST_SLIDE_INSTRUCTIONS: {
    english: `
    <p> Once you reach the last slide of
    <b class"underline">each section</b>, you will click on
    <b class"underline">"Next Content"</b> and a three to five
    question quiz will pop up on the top left corner. All quizzes are
    multiple choice. You will repeat this step throughout each section.
    Upon completing all sections, you will have a timed multiple choice
    final exam. You will have three attempts to receive a passing
    percentage before having to retake the whole course. </p>
  `,
    spanish: `<p> Cuando llegue a la última imagen de <b class"underline">cada sección</b>, seleccione, <b class"underline">"Contenido Siguiente"</b> y aparecerá un cuestionario de tres a cinco preguntas en la esquina superior izquierda. Todos los cuestionarios son de opción múltiple. Repetirá este paso en cada sección. Al completar todas las secciones, realizará un examen final de opción múltiple cronometrado. Tendrá tres intentos para recibir un porcentaje aprobatorio, antes de tener que volver a realizar todo el curso. </p>`,
  },

  LAST_SLIDE_INSTRUCTIONS_BOTH: {
    english: `<p> Once you reach the last slide of
    <b class"underline">each section</b>, you will click on
    <b class"underline">"Next Content"</b> and a three to five
    question quiz will pop up on the top left corner (for GMP's course only). All quizzes are
    multiple choice. You will repeat this step throughout each section of both modules, "GMPs in Food Manufacturing" and "Sanitation Standard Operating Procedures."
    Upon completing all sections of each module, you will have a timed multiple choice
    final exam. You will have three attempts to receive a passing
    percentage before having to retake the whole course. </p>`,
    spanish: `<p> Cuando llegue a la última imagen de <b class"underline">cada sección</b>, seleccione, <b class"underline">"Contenido Siguiente"</b> y aparecerá un cuestionario de tres a cinco preguntas en la esquina superior izquierda. Todos los cuestionarios son de opción múltiple. Repetirá este paso en cada sección. Al completar todas las secciones, realizará un examen final de opción múltiple cronometrado. Tendrá tres intentos para recibir un porcentaje aprobatorio, antes de tener que volver a realizar todo el curso. </p>`,
  },

  CONTACT_INFORMATION: {
    english: "Contact Information",
    spanish: "Información de Contacto",
  },

  QUESTIONS_CONCERNS: {
    english: "Questions/Concerns?",
    spanish: "¿Preguntas?",
  },
};

const translateMaybe = (
  text: keyof typeof TRANSLATIONS,
  language: "spanish" | "english"
) => {
  return TRANSLATIONS[text]?.[language] ?? "";
};

const SuccessMessage = ({
  courses,
  language,
  code,
}: {
  courses: { title: string }[];
  code: string;
  language: "english" | "spanish";
}) => {
  const bothCourses = courses.length === 2;

  const courseInstructions = bothCourses
    ? translateMaybe("MAIN_PAGE_INSTRUCTIONS_BOTH", language)
    : translateMaybe("MAIN_PAGE_INSTRUCTIONS_ONE", language)
        .replace("COURSE_NAME", courses[0].title)
        .replace(
          "FIRST_SECTION",
          courseIntroMap[courses[0].title as CourseType]
        );

  return (
    <div className="text-black">
      <div className="text-center select-text">
        <h1 className="text-2xl font-bold py-2 mt-4 font-serif">
          {translateMaybe("WELCOME", language)}
        </h1>
      </div>

      <div className="mt-4 max-w-3xl mx-auto">
        <p className="my-2">{translateMaybe("INTRO", language)}</p>
        <div className="my-2">
          <ol className="list-none space-y-1">
            <li>
              {translateMaybe("STEP_1", language)}
              <a href="https://app.foodsmartstrategies.com/signup">
                https://app.foodsmartstrategies.com/signup
              </a>
            </li>
            <li>
              <b className="underline">{translateMaybe("STEP_2", language)}</b>
            </li>
            <li>
              <b className="underline mr-2">
                {translateMaybe("STEP_3", language)}
              </b>
              <span className="bg-gray-200 p-1 select-all">{code}</span>
            </li>
            <li>
              <b className="underline">{translateMaybe("STEP_4", language)}</b>
            </li>
          </ol>
        </div>
        <p className="my-2">{courseInstructions}</p>
        <p className="my-2">
          {translateMaybe("IMAGE_SLIDER_INSTRUCTIONS", language)}
        </p>

        <div
          className="my-2"
          dangerouslySetInnerHTML={{
            __html: translateMaybe(
              bothCourses
                ? "LAST_SLIDE_INSTRUCTIONS_BOTH"
                : "LAST_SLIDE_INSTRUCTIONS",
              language
            ),
          }}
        ></div>

        <p>
          {translateMaybe("QUESTIONS_CONCERNS", language)}
          <br />
          {translateMaybe("CONTACT_INFORMATION", language)}
          <br />
          Trish Nunez <br />
          C/T: 213.880.7661 <br />
          E:
          <a href="emailto:tnunez@foodsmartstrategies.com">
            tnunez@foodsmartstrategies.com
          </a>
        </p>
      </div>

      <div className="flex flex-row mt-4 py-2 justify-end">
        <Link
          to="/"
          className="text-white bg-green-500 font-medium px-3 py-1 rounded-md hover:bg-green-700 border border-green-700"
        >
          Back to home
        </Link>
      </div>
    </div>
  );
};

export default SuccessMessage;
