import React, { useMemo, useEffect, useState, useContext } from "react";
import { IonContent, IonInfiniteScroll, IonPage, IonIcon } from "@ionic/react";
import { ribbon } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import isEmpty from "lodash/isEmpty";
import { db, flamelinkApp as flamelink } from "../../firebase";
import { AuthContext } from "../../Providers/UserProvider";

interface ExamProps {
  examTitle: string;
  timeLimit: number;
  id: string;
}

interface StudentExamProps {
  finalExamAttempts: {
    answers: any;
    finalExamCorrectCount: number;
    finalExamIncorrectCount: number;
  }[];
  finalExamPassed: boolean | null;
}

const FinalExamStaging: React.FC<RouteComponentProps<{ courseId: string }>> = ({
  match,
  history,
}) => {
  const { studentId } = useContext(AuthContext);

  const courseId = useMemo(
    () => (match.params ? match.params.courseId : null),
    [match]
  );
  const [exam, setExam] = useState({} as ExamProps);
  const [studentExamData, setStudentExamData] = useState(
    {} as StudentExamProps
  );

  const maxExamAttempts = 3;
  const attemptsRemaining = useMemo(
    () => maxExamAttempts - studentExamData.finalExamAttempts?.length,
    [maxExamAttempts, studentExamData]
  );

  useEffect(() => {
    if (!courseId) {
      return;
    }
    (async () => {
      const courseRef = db.doc(`/fl_content/${courseId}`);
      const examData = await flamelink.content.get({
        schemaKey: "finalExam",
        fields: ["timeLimit", "examTitle", "id"],
        filters: [["course", "==", courseRef]],
      });
      if (!examData || isEmpty(examData)) {
        history.push("/home");
      }
      setExam(Object.values(examData ?? {})?.[0] as ExamProps);
    })();
  }, [courseId, history]);

  useEffect(() => {
    (async () => {
      await db
        .doc(`/students/${studentId}/courses/${courseId}`)
        .get()
        .then((snap) =>
          setStudentExamData({
            finalExamAttempts: snap.data()?.finalExamAttempts,
            finalExamPassed: snap.data()?.finalExamPassed,
          })
        )
        .catch((e) => {
          history.push("/home");
        });
    })();
  }, [studentId, courseId, history]);

  return (
    <IonPage>
      <IonContent>
        <IonInfiniteScroll>
          <div className="absolute inset-0 bg-no-repeat bg-cover bg-center bg-hero-bg text-black">
            <div className="container mx-auto px-4">
              <div className="rounded-md shadow-md bg-white p-4 mt-16 mx-auto md:max-w-1/2">
                <div className="mb-3">
                  <h1 className="text-xl text-center font-bold tracking-wider">
                    {exam.examTitle}
                    {studentExamData.finalExamPassed && (
                      <span className="align-middle pl-2">
                        <IonIcon icon={ribbon} className="pr-1 text-success" />
                      </span>
                    )}
                  </h1>

                  {studentExamData.finalExamPassed ? (
                    <p className="text-center text-success">
                      You've passed this exam!
                    </p>
                  ) : (
                    <p className="text-center text-gray-500">
                      {`${attemptsRemaining} attempt${
                        attemptsRemaining === 1 ? "" : "s"
                      } remaining`}
                    </p>
                  )}
                </div>
                <p>
                  For this exam, you'll have {exam.timeLimit} minutes. Make sure
                  you have enough free time right now to take this entire exam;
                  once you start it, you won't be able to go back.
                </p>
                {!studentExamData.finalExamPassed && (
                  <p>
                    If you run out of attempts on this exam,{" "}
                    <span className="text-error">
                      your progress will be reset
                    </span>{" "}
                    and you'll have to retake the course.
                  </p>
                )}
                <div className="text-center flex flex-row justify-around mt-2">
                  <button
                    onClick={() => history.push(`/home`)}
                    className="bg-error hover:bg-error-dark rounded-md px-4 py-1 text-white font-medium my-2"
                  >
                    Back to home
                  </button>
                  <button
                    onClick={() => history.push(`/exam/${courseId}`)}
                    className="bg-success hover:bg-success-dark rounded-md px-4 py-1 text-white font-semibold tracking-wider my-2"
                  >
                    Begin exam
                  </button>
                </div>
              </div>
            </div>
          </div>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default FinalExamStaging;
