import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { showError } from "../../../Services/ui";
import FullButton from "../../../components/FullButton";
import firebase from "../../../firebase";

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const login = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(showError("Unable to log in") as any)
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <div className="shadow-lg rounded-lg bg-white">
      <form onSubmit={login}>
        <div className="p-3 md:p-6">
          <h2 className="text-3xl text-black">Welcome to Food Academy</h2>
          <div className="mt-5">
            <div className="text-left my-2">
              <label htmlFor="email" className="text-base text-black">
                Email
                <input
                  required
                  type="text"
                  id="email"
                  autoComplete="username"
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="block rounded-md border-2 border-gray-200 mt-1 px-1 py-1 md:px-2 bg-white text-black focus:outline-none focus:border-blue-500 transition-colors w-full"
                />
              </label>
            </div>
            <div className="text-left mt-2 mb-6">
              <label htmlFor="password" className="text-base text-black">
                Password
                <input
                  required
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  name="password"
                  onChange={(e) => setPassword(e.target.value!)}
                  className="block rounded-md border-2 border-gray-200 mt-1 px-1 py-1 md:px-2 bg-white text-black focus:outline-none focus:border-blue-500 transition-colors w-full"
                />
              </label>
            </div>
          </div>
        </div>
        <div className="pb-5 text-center">
          <button
            type="button"
            className="cursor-pointer bg-transparent text-primary"
            onClick={() => history.push("/forgot-password")}
          >
            Forgot password?
          </button>
        </div>
        <div className="text-center">
          <FullButton text="Log in" disabled={loading} buttonType="submit" />
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
