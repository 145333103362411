import React from "react";
import { IonIcon } from "@ionic/react";
import {
  checkboxOutline,
  image,
  playCircle,
  document,
  squareOutline,
} from "ionicons/icons";
import { useHistory } from "react-router";

enum ContentType {
  Video = "video",
  Markdown = "markdown",
  Quiz = "quiz",
  Slideshow = "slideshow",
}

interface ContentCollectionProps {
  title: string;
  orderIndex: number;
  contentType: ContentType;
  courseId: string;
  isComplete: boolean;
  id: string;
  isActive: boolean;
}

const ContentCollection: React.FC<ContentCollectionProps> = ({
  title,
  orderIndex,
  id,
  contentType,
  courseId,
  isComplete = false,
  isActive = false,
}) => {
  const history = useHistory();
  const getIcon = () => {
    if (contentType === ContentType.Video) {
      return <IonIcon icon={playCircle} />;
    } else if (contentType === ContentType.Slideshow) {
      return <IonIcon icon={image} />;
    } else {
      return <IonIcon icon={document} />;
    }
  };

  return (
    <button
      type="button"
      className="w-full"
      onClick={() => {
        if (isActive || isComplete) {
          history.push(`/content/${courseId}/${id}`);
        }
      }}
    >
      <div
        className={`flex flex-row flex-nowrap pl-8 pt-2 pr-4 ${
          isActive ? "bg-gray-200" : "bg-white"
        } ${isActive || isComplete ? "hover:bg-gray-300" : ""}`}
      >
        <div className="h-full px-2">
          {isComplete ? (
            <IonIcon icon={checkboxOutline} className="text-success" />
          ) : (
            <IonIcon icon={squareOutline} className="text-black" />
          )}
        </div>
        <div className="h-full text-black">
          <h2 className="text-md text-left">
            {orderIndex}. {title}
          </h2>
          <div
            className={`pt-1 pb-2 flex flex-row justify-start ${
              isComplete ? "text-success" : "text-black"
            }`}
          >
            {getIcon()}
          </div>
        </div>
      </div>
    </button>
  );
};

export default ContentCollection;
