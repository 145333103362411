import { useState } from "react";
import {
  IonPage,
  IonContent,
  IonInfiniteScroll,
  IonRouterLink,
  IonImg,
} from "@ionic/react";
import isEmpty from "lodash/isEmpty";
import firebase from "../../firebase";
import SecondaryButton from "../../components/SecondaryButton";
import FullButton from "../../components/FullButton";
import logo from "../../assets/logo.png";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState({} as { msg: string; isError: boolean });
  const [loading, setLoading] = useState(false);

  const sendResetEmail = (event: any) => {
    setLoading(true);
    event.preventDefault();
    const auth = firebase.auth();
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setAlert({ msg: "Password reset email sent!", isError: false });
        setEmail("");
      })
      .catch((e) => {
        if (e.code === "auth/user-not-found") {
          setAlert({
            msg: "Email not found! Please check your email address and try again.",
            isError: true,
          });
        } else {
          setAlert({
            msg: "Error sending reset link! Please try again.",
            isError: true,
          });
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <IonPage>
      <IonContent>
        <IonInfiniteScroll className="bg-cover min-h-screen bg-center bg-no-repeat bg-hero-bg">
          <div className="text-right p-4">
            <IonRouterLink href="/login">
              <SecondaryButton text="Log In" />
            </IonRouterLink>
          </div>
          <div className="max-w-xs mx-auto my-3">
            <IonImg src={logo} />
          </div>
          <div className="mx-auto mb-auto text-center p-4 pt-8 container md:max-w-1/2">
            <div className="shadow-lg rounded-lg bg-white">
              <form onSubmit={sendResetEmail}>
                <div className="p-3 md:p-6">
                  <h2 className="text-3xl text-black">Password Reset</h2>
                  <div className="mt-5">
                    {!isEmpty(alert) && (
                      <div
                        className={`px-4 py-2 rounded-md ${
                          alert.isError
                            ? "bg-red-50 text-red-700"
                            : "bg-green-50 text-green-700"
                        }`}
                      >
                        <p>{alert.msg}</p>
                      </div>
                    )}
                    <div className="text-left my-2">
                      <label htmlFor="email" className="text-base text-black">
                        Email
                        <input
                          required
                          type="text"
                          id="email"
                          autoComplete="username"
                          value={email}
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          className="block rounded-md border-2 border-gray-200 mt-1 px-1 py-1 md:px-2 bg-white text-black focus:outline-none focus:border-blue-500 transition-colors w-full"
                        />
                      </label>
                      <p className="text-xs mt-1">
                        Please enter the email associated with your account, and
                        you'll be sent a password reset link.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <FullButton
                    text="Reset password"
                    disabled={loading}
                    buttonType="submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
