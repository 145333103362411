import {
  IonPage,
  IonRouterLink,
  IonImg,
  IonContent,
  IonInfiniteScroll,
} from "@ionic/react";
import LoginForm from "./LoginForm";
import SecondaryButton from "../../components/SecondaryButton";
import logo from "../../assets/logo.png";

const Content: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <IonInfiniteScroll className="bg-cover min-h-screen bg-center bg-no-repeat bg-hero-bg">
          <div className="text-right p-4">
            <IonRouterLink href="/signup">
              <SecondaryButton text="Sign Up" />
            </IonRouterLink>
          </div>
          <div className="max-w-xs mx-auto my-3">
            <IonImg src={logo} />
          </div>
          <div className="mx-auto mb-auto text-center p-4 pt-8 container md:max-w-1/2">
            <LoginForm />
          </div>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default Content;
