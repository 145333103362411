import { IonApp } from "@ionic/react";
import Router from "./Shared/Router";
import { UserProvider } from "./Providers/UserProvider";
import { CourseTimerProvider } from "./Providers/CourseTimerProvider";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import CourseProvider from "./Providers/CourseProvider";

const App: React.FC = () => (
  <IonApp>
    <UserProvider>
      <CourseProvider>
        <CourseTimerProvider>
          <Router />
        </CourseTimerProvider>
      </CourseProvider>
    </UserProvider>
  </IonApp>
);

export default App;
