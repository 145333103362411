import React, { useState, createContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { auth, db } from "../firebase";

const defaultFirebaseContext = {
  user: null,
  student: null,
  studentId: "",
  initialized: false,
  studentsRef: null as any,
};

export const AuthContext = createContext(defaultFirebaseContext);

export const UserProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useState(null as any);
  const [student, setStudent] = useState(null as any);
  const [isInitialized, setIsInitialized] = useState(false);
  const [userInitialized, setUserInitialized] = useState(false);
  const studentsRef = db.collection(`students/`);

  useEffect(() => {
    return auth.onAuthStateChanged(async (u: any) => {
      if (u) {
        try {
          const idTokenResult = await u.getIdTokenResult();
          setUser({
            ...u,
            uid: u.uid,
            claims: idTokenResult.claims,
          });
        } catch (error) {
          setUser(null);
        }
      } else {
        setUser(null);
      }
      setUserInitialized(true);
    });
  }, []);

  useEffect(() => {
    if (userInitialized) {
      if (user) {
        return db
          .collection(`/students`)
          .doc(user.uid)
          .onSnapshot((studentDoc) => {
            if (studentDoc.exists) {
              setStudent({
                ...studentDoc.data(),
                id: studentDoc.id,
              });
              setIsInitialized(true);
            } else {
              auth.signOut();
            }
          });
      }
      setStudent(null);
      setIsInitialized(true);

      if (user) {
        auth.signOut();
      }
    }
    return () => {};
  }, [user, userInitialized]);

  const studentId = useMemo(() => student?.id, [student]);

  return (
    <AuthContext.Provider
      value={{
        user,
        student,
        studentId,
        initialized: isInitialized,
        studentsRef,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
