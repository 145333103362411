import logo from "../../assets/logo.png";
import { IonImg } from "@ionic/react";
const Loader = () => {
  return (
    <div className="absolute inset-0 bg-cover min-h-screen bg-center bg-no-repeat bg-hero-bg">
      <div className="max-w-xs mx-auto my-3 px-4 mt-16">
        <IonImg src={logo} />
      </div>
      <div className="flex flex-row justify-center text-center mx-auto mt-16">
        <div
          className="animate-spin w-36 h-36 border-b-8 border-success"
          style={{ borderRadius: "100px" }}
        ></div>
      </div>
      <div className="flex flex-row justify-center text-center mx-auto mt-8">
        <h1 className="text-2xl text-gray-800 animate-pulse bg-gray-200 rounded-xl p-4">
          Loading...
        </h1>
      </div>
    </div>
  );
};

export default Loader;
