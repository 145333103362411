import React, { createContext } from "react";
import PropTypes from "prop-types";
import firebase, { db } from "../firebase";

const defaultFirebaseContext = {
  logStartTime: async ({ contentId }: { contentId: string }) => Promise as any,
  logCheckIn: async ({ contentId }: { contentId: string }) => Promise as any,
  logStopTimeAndSync: async ({
    courseId,
    studentId,
    contentId,
  }: {
    courseId: string;
    studentId: string;
    contentId: string;
  }) => Promise as any,
  syncTimeLogged: async ({
    courseId,
    studentId,
    contentId,
  }: {
    courseId: string;
    studentId: string;
    contentId: string;
  }) => Promise as any,
};

export const CourseTimerContext = createContext(defaultFirebaseContext);

export const CourseTimerProvider = ({ children }: { children: any }) => {
  const logStartTime = async ({ contentId }: { contentId: string }) => {
    try {
      localStorage.setItem(
        contentId,
        JSON.stringify({ startTime: new Date().getTime() })
      );
    } catch (e) {}
  };

  const logCheckIn = async ({ contentId }: { contentId: string }) => {
    try {
      const startTime =
        JSON.parse(localStorage.getItem(contentId) ?? "{}").startTime ??
        new Date().getTime();
      localStorage.setItem(
        contentId,
        JSON.stringify({ startTime: startTime, endTime: new Date().getTime() })
      );
    } catch (e) {}
  };

  const syncTimeLogged = async ({
    studentId,
    courseId,
    contentId,
  }: {
    studentId: string;
    courseId: string;
    contentId: string;
  }) => {
    try {
      const { startTime, endTime } = JSON.parse(
        localStorage.getItem(contentId) ?? ""
      );
      if (!startTime || !endTime) {
        return;
      }

      const timeData = {
        startTime: startTime,
        endTime: endTime,
      };

      await db
        .doc(`/students/${studentId}/courses/${courseId}/contents/${contentId}`)
        .update({
          timestamps: firebase.firestore.FieldValue.arrayUnion(timeData),
        });
    } catch (e) {}
  };

  const logStopTimeAndSync = async ({
    studentId,
    courseId,
    contentId,
  }: {
    studentId: string;
    courseId: string;
    contentId: string;
  }) => {
    try {
      const startTime = JSON.parse(
        localStorage.getItem(contentId) ?? ""
      ).startTime;
      localStorage.setItem(
        contentId,
        JSON.stringify({ startTime: startTime, endTime: new Date().getTime() })
      );
      await syncTimeLogged({ studentId, courseId, contentId });
    } catch (e) {}
  };

  return (
    <CourseTimerContext.Provider
      value={{
        logStartTime,
        logCheckIn,
        syncTimeLogged,
        logStopTimeAndSync,
      }}
    >
      {children}
    </CourseTimerContext.Provider>
  );
};

CourseTimerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
