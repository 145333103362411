import React from "react";
import { useHistory } from "react-router-dom";
import { CourseTimerContext } from "../../Providers/CourseTimerProvider";
import { functions } from "../../firebase";

interface NextContentButtonProps {
  contentId: string;
  currentContent: any;
  studentId: string;
  courseId: string;
  setLoading: any;
}

const NextContentButton: React.FC<NextContentButtonProps> = ({
  contentId,
  currentContent,
  studentId,
  courseId,
  setLoading,
}) => {
  const history = useHistory();

  const { logStopTimeAndSync } = React.useContext(CourseTimerContext);

  const moveToNextContent = async () => {
    setLoading(true);
    await logStopTimeAndSync({
      contentId,
      studentId,
      courseId,
    });

    const { nextContentId } = (
      await functions.httpsCallable("moveToNextContent")({
        studentId,
        courseId,
        contentId,
        currentSectionId: currentContent?.section?.id,
      })
    ).data;

    if (nextContentId === null) {
      history.push(`/exam-staging/${courseId}`);
      return;
    }
    history.push(`/content/${courseId}/${nextContentId}`);
  };

  return (
    <div className="text-center p-2 bg-white">
      <button
        type="button"
        className={`rounded-md text-white font-bold px-4 py-2 text-base shadow-md bg-success hover:bg-success-dark`}
        onClick={async () => {
          await moveToNextContent();
        }}
      >
        Next content
      </button>
    </div>
  );
};

export default NextContentButton;
