import React, { useRef, useEffect } from "react";
import { IonSlides, IonSlide } from "@ionic/react";

interface Slide {
  id: string;
  url: string;
}

interface SlideshowContentProps {
  slideshow: Slide[];
  id: string;
  setIsContentCompleted: (completed: boolean) => void;
}

const SlideshowContent: React.FC<SlideshowContentProps> = ({
  slideshow,
  id,
  setIsContentCompleted,
}) => {
  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        slidesRef.current?.slidePrev();
      }
      if (event.key === "ArrowRight") {
        slidesRef.current?.slideNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const isPager = slideshow.length > 1;

  return (
    <div className="bg-black">
      <div className="max-w-5xl mx-auto">
        <IonSlides
          ref={slidesRef}
          pager={isPager}
          onIonSlideReachEnd={() => setIsContentCompleted(true)}
          key={id}
        >
          {slideshow.map((imageObj) => (
            <IonSlide key={imageObj.id} className="my-auto max-h-screen">
              <img
                className="h-1/2 sm:h-3/5 max-h-screen"
                src={imageObj.url}
                alt="Slideshow content"
                style={{ width: "100%" }}
              />
            </IonSlide>
          ))}
        </IonSlides>
        {isPager && (
          <p className="text-white text-center">
            You can use your arrow keys to paginate between slides
          </p>
        )}
      </div>
    </div>
  );
};

export default SlideshowContent;
