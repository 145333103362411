import React, { useState, useMemo, useContext } from "react";
import { default as _ReactPlayer } from "react-player/lazy";
import { ReactPlayerProps } from "react-player/types/lib";

import { AuthContext } from "../../../Providers/UserProvider";
import Modal from "../../../components/Modal";
import { db } from "../../../firebase";
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

interface Answer {
  answerOption: string;
  isCorrectAnswer: boolean;
  wasSelected?: boolean;
  uniqueKey: string;
}

interface TextQuizContentProps {
  quizQuestion: string;
  answers: Answer[];
  animationsCorrect: any[];
  animationsIncorrect: any[];
  setIsContentCompleted: any;
  courseId: string;
  contentId: string;
  isComplete: boolean;
}

const TextQuizContent: React.FC<TextQuizContentProps> = ({
  quizQuestion,
  answers,
  animationsCorrect,
  animationsIncorrect,
  setIsContentCompleted,
  courseId,
  contentId,
  isComplete,
}) => {
  const { studentId } = useContext(AuthContext);
  const [isQuizFinished, setIsQuizFinished] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const hideModal = () => {
    setShowModal(false);
    setIsQuizFinished(true);
  };

  const animationCorrect = useMemo(
    () =>
      animationsCorrect
        ? animationsCorrect[
            Math.floor(Math.random() * animationsCorrect.length)
          ]
        : null,
    [animationsCorrect]
  );

  const animationIncorrect = useMemo(
    () =>
      animationsIncorrect
        ? animationsIncorrect[
            Math.floor(Math.random() * animationsIncorrect.length)
          ]
        : null,
    [animationsIncorrect]
  );

  const getModalBody = () => {
    return (
      <ReactPlayer
        url={
          isAnswerCorrect
            ? animationCorrect?.animationFile?.[0]?.url
            : animationIncorrect?.animationFile?.[0]?.url
        }
        width="100%"
        height="auto"
        controls={false}
        onEnded={hideModal}
        playing={true}
        volume={0}
        muted={true}
      />
    );
  };

  const gradeQuizAnswer = async (answer: Answer) => {
    if (isQuizFinished) {
      return;
    }
    answer.wasSelected = true;
    setIsAnswerCorrect(answer.isCorrectAnswer);
    setIsContentCompleted(true);
    setShowModal(true);
    if (isComplete) {
      return;
    }
    await db
      .doc(`/students/${studentId}/courses/${courseId}/contents/${contentId}`)
      .update({
        quizQuestionCorrect: answer.isCorrectAnswer,
      });
  };

  return (
    <div>
      <div className="pb-3 text-black bg-gray-100">
        <h2 className="pt-2 pl-4 pb-3">{quizQuestion}</h2>
        {answers.map((answer) => (
          <button
            key={answer.uniqueKey}
            type="button"
            className="w-full text-left"
            onClick={() => gradeQuizAnswer(answer)}
          >
            <div
              className={`p-2 mb-2 mx-4 rounded-md shadow-md bg-white font-semibold ${
                (answer.wasSelected || answer.isCorrectAnswer) && isQuizFinished
                  ? "text-white"
                  : "text-black"
              } ${
                (answer.wasSelected || answer.isCorrectAnswer) &&
                isQuizFinished &&
                (answer.isCorrectAnswer ? "bg-success" : "bg-error")
              }`}
            >
              {answer.answerOption}
            </div>
          </button>
        ))}
      </div>
      {showModal && (
        <Modal
          showHeader={false}
          hideModal={hideModal}
          children={getModalBody()}
        />
      )}
    </div>
  );
};

export default TextQuizContent;
