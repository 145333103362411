import React, { useMemo } from "react";
import { IonIcon } from "@ionic/react";
import { ribbon, book } from "ionicons/icons";
import isEmpty from "lodash/isEmpty";
interface ExamAttemptProps {
  answers: any;
  finalExamCorrectCount: number;
  finalExamIncorrectCount: number;
}
interface CourseListingProps {
  title: string;
  description: string;
  isLoading: boolean;
  numberContentsInCourse?: number;
  numberContentsCompleted?: number;
  finalExamAttempts: ExamAttemptProps[];
  currentContentTitle?: string;
  onOpenCourse?: () => void;
  onOpenExam?: () => void;
  finalExamPassed?: boolean | null;
}

const CourseListing: React.FC<CourseListingProps> = ({
  description,
  title,
  isLoading,
  numberContentsInCourse,
  numberContentsCompleted,
  finalExamAttempts,
  currentContentTitle,
  onOpenCourse = () => {},
  onOpenExam = () => {},
  finalExamPassed,
}) => {
  const allContentsCompleted = useMemo(
    () => numberContentsCompleted === numberContentsInCourse,
    [numberContentsCompleted, numberContentsInCourse]
  );

  const progress = useMemo(() => {
    if (!numberContentsCompleted || numberContentsInCourse === undefined) {
      return null;
    }
    return Math.round((numberContentsCompleted * 100) / numberContentsInCourse);
  }, [numberContentsCompleted, numberContentsInCourse]);

  const bestExamScore = useMemo(() => {
    if (!finalExamAttempts) {
      return null;
    }
    return Math.max(
      ...finalExamAttempts.map((attempt: ExamAttemptProps) =>
        Math.round(
          (100 * attempt.finalExamCorrectCount) /
            (attempt.finalExamCorrectCount + attempt.finalExamIncorrectCount)
        )
      )
    );
  }, [finalExamAttempts]);

  return (
    // <IonRouterLink className="cursor-pointer" onClick={onOpenCourse}>
    <div
      className={`rounded-lg shadow-lg m-2 max-h-70 min-w-64 w-64 flex-none ${
        isLoading ? "bg-gray-`00 opacity-50" : "bg-white"
      }`}
    >
      <div className="px-4 py-2">
        <div className="flex flex-row flex-nowrap justify-between ">
          <h2 className="text-black font-semibold text-lg my-auto">{title}</h2>
          {!isEmpty(finalExamAttempts) && bestExamScore !== null && (
            <div className="flex flex-row flex-nowrap items-center">
              {finalExamPassed ? (
                <IonIcon icon={ribbon} className="pr-1 text-success"></IonIcon>
              ) : (
                <IonIcon icon={book} className="pr-1 text-error"></IonIcon>
              )}
              <h2
                className={`my-auto font-semibold ${
                  bestExamScore !== null && finalExamPassed
                    ? "text-success"
                    : "text-error"
                }`}
              >
                {bestExamScore}%
              </h2>
            </div>
          )}
        </div>
        <hr />
      </div>
      <div className="pb-3 px-4">
        {isLoading && (
          <div className="flex flex-row align-middle">
            <div
              className="animate-spin w-4 h-4 border-b-2 border-success my-auto mr-3"
              style={{ borderRadius: "100px" }}
            ></div>
            <p className="text-black">Loading content...</p>
          </div>
        )}
        {!isLoading && currentContentTitle !== undefined && description && (
          <div>
            <p className="text-black line-clamp-4 my-3">{description}</p>
            {finalExamPassed && (
              <p className="text-success text-sm">Course complete!</p>
            )}
            <small className="text-black">
              {finalExamPassed ? "Review:" : "Next up:"}{" "}
              <button onClick={onOpenCourse}>
                <span className="text-primary">{currentContentTitle}</span>
              </button>
            </small>
          </div>
        )}
      </div>
      {progress && (
        <div className="pb-4 px-2">
          <div className="flex mb-2 items-center justify-between">
            <div>
              <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-primary bg-blue-200">
                Progress
              </span>
            </div>
            <div className="text-right">
              <span className="text-xs font-semibold inline-block text-primary">
                {`${progress}%`}
              </span>
            </div>
          </div>
          <div className="overflow-hidden h-3 text-xs flex rounded bg-blue-200 rounded-b-md">
            <div
              style={{
                width: `${progress}%`,
              }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary rounded-none"
            ></div>
          </div>
        </div>
      )}
      {allContentsCompleted && (
        <div className="px-2 pt-1 pb-2 text-center">
          <button
            className="rounded-md bg-primary hover:bg-primary-dark shadow-md px-3 py-1 text-white font-bold tracking-wide"
            onClick={onOpenExam}
          >
            Take exam
          </button>
        </div>
      )}
    </div>
    // </IonRouterLink>
  );
};

export default CourseListing;
