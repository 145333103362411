interface FullButtonProps {
  text: string;
  bgColor?: string;
  disabled?: boolean;
  buttonType?: "button" | "submit";
}

const FullButton: React.FC<FullButtonProps> = ({
  text,
  bgColor,
  disabled = false,
  buttonType = "button",
}) => {
  return (
    <button
      type={buttonType}
      className={`text-white font-bold px-4 py-2 text-lg w-full uppercase rounded-b-md ${
        bgColor ?? "bg-primary"
      }`}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default FullButton;
