const ContentLoader = () => {
  return (
    <div className="h-1/2 flex flex-col text-center justify-center items-center bg-white text-black">
      <div className="flex flex-row">
        <div
          className="animate-spin w-8 h-8 border-b-4 border-success mr-2"
          style={{ borderRadius: "100px" }}
        ></div>
        <h2 className="text-center self-center text-2xl">Loading...</h2>
      </div>
    </div>
  );
};

export default ContentLoader;
