import { IonContent, IonInfiniteScroll, IonPage } from "@ionic/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom-v5-compat";
import { functions } from "../../firebase";
import Loader from "../../components/Loader";
import SuccessMessage from "../../components/Signup/SuccessMessage";

const SuccessPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  const sessionId = searchParams.get("session_id");
  const locale = searchParams.get("locale") ?? "";
  const emailForce = searchParams.get("emailforce") ?? "";

  const [loading, setLoading] = useState<boolean>(true);
  const [code, setCode] = useState<null | string>(null);
  const [courses, setCourses] = useState<
    null | { id: string; title: string }[]
  >(null);

  const generateCourseToken = useCallback(async () => {
    if (!sessionId) {
      return;
    }
    functions
      .httpsCallable("generateToken")({
        sessionId,
        ...(emailForce?.includes("@") && {
          emailForce,
        }),
      })
      .then((resp) => {
        if (resp.data.code && resp.data.courses) {
          setCode(resp.data.code);
          setCourses(resp.data.courses);
        }
      })
      .catch(() => {
        // pass
      })
      .finally(() => setLoading(false));
  }, [sessionId]);

  useEffect(() => {
    generateCourseToken();
  }, [generateCourseToken]);

  const errorMessage = useMemo(() => {
    return (
      <div className="text-center py-3 max-x-xl">
        <h1 className="font-bold text-2xl mb-2">Oops!</h1>
        <p className="text-lg">
          Looks like you got to this page by mistake. Would you like to{" "}
          <Link to="/" className="text-blue-500 underline">
            head home?
          </Link>
        </p>
      </div>
    );
  }, []);

  if (loading) {
    return (
      <IonPage>
        <IonContent fullscreen>
          <div className="flex flex-col h-full bg-gray-100 justify-center items-center">
            <Loader />
          </div>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonInfiniteScroll className="bg-cover min-h-screen bg-center bg-no-repeat bg-hero-bg pt-16">
          <div className="bg-gray-100 max-w-2xl mx-auto p-4 rounded-md select-text">
            {code && courses ? (
              <SuccessMessage
                language={locale.includes("es") ? "spanish" : "english"}
                courses={courses}
                code={code}
              />
            ) : (
              errorMessage
            )}
          </div>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default SuccessPage;
