import React from "react";

interface LoaderWithoutImageProps {
  message?: string;
}

const LoaderWithoutImage: React.FC<LoaderWithoutImageProps> = ({
  message = "Loading...",
}) => {
  return (
    <div>
      <div className="flex flex-row justify-center text-center mx-auto mt-16">
        <div
          className="animate-spin w-36 h-36 border-b-8 border-success"
          style={{ borderRadius: "100px" }}
        ></div>
      </div>
      <div className="flex flex-row justify-center text-center mx-auto mt-8">
        <h1 className="text-2xl text-gray-800 animate-pulse bg-gray-200 rounded-xl p-4">
          {message}
        </h1>
      </div>
    </div>
  );
};

export default LoaderWithoutImage;
