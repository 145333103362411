import React, { useContext, useState, useMemo } from "react";
import { default as _ReactPlayer } from "react-player/lazy";
import { ReactPlayerProps } from "react-player/types/lib";
import ContentLoader from "../../../components/ContentLoader";
import Modal from "../../../components/Modal";
import { AuthContext } from "../../../Providers/UserProvider";
import { db } from "../../../firebase";
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

interface AnswerImage {
  url: string;
}
interface Answer {
  answerImage: AnswerImage[];
  isCorrectAnswer: boolean;
  wasSelected?: boolean;
  uniqueKey: string;
}

interface ImageQuizContentProps {
  animationsCorrect: any[];
  animationsIncorrect: any[];
  quizQuestion: string;
  answers: Answer[];
  setIsContentCompleted: any;
  courseId: string;
  contentId: string;
  isComplete: boolean;
}

const ImageQuizContent: React.FC<ImageQuizContentProps> = ({
  quizQuestion,
  answers,
  isComplete,
  animationsCorrect,
  animationsIncorrect,
  setIsContentCompleted,
  courseId,
  contentId,
}) => {
  const { studentId } = useContext(AuthContext);

  const [isQuizFinished, setIsQuizFinished] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const hideModal = () => {
    setShowModal(false);
    setIsQuizFinished(true);
  };

  const animationCorrect = useMemo(
    () =>
      animationsCorrect
        ? animationsCorrect[
            Math.floor(Math.random() * animationsCorrect.length)
          ]
        : null,
    [animationsCorrect]
  );

  const animationIncorrect = useMemo(
    () =>
      animationsIncorrect
        ? animationsIncorrect[
            Math.floor(Math.random() * animationsIncorrect.length)
          ]
        : null,
    [animationsIncorrect]
  );

  const getModalBody = () => {
    return (
      <ReactPlayer
        url={
          isAnswerCorrect
            ? animationCorrect?.animationFile?.[0]?.url
            : animationIncorrect?.animationFile?.[0]?.url
        }
        width="100%"
        height="auto"
        controls={false}
        onEnded={hideModal}
        playing={true}
        volume={0}
        muted={true}
      />
    );
  };

  const gradeQuizAnswer = async (answer: Answer) => {
    if (isQuizFinished) {
      return;
    }
    answer.wasSelected = true;
    setIsAnswerCorrect(answer.isCorrectAnswer);
    setIsContentCompleted(true);
    setShowModal(true);
    if (isComplete) {
      return;
    }
    await db
      .doc(`/students/${studentId}/courses/${courseId}/contents/${contentId}`)
      .update({
        quizQuestionCorrect: answer.isCorrectAnswer,
      });
  };

  return (
    <div>
      <div
        className={`pb-3 text-black bg-gray-100 ${loading ? "invisible" : ""}`}
      >
        <h2 className="pt-3 pl-4 pb-1 text-xl text-center">{quizQuestion}</h2>
        <div className="grid grid-cols-2 p-2 max-w-2xl mx-auto">
          {answers.map((answer: Answer) => (
            <button
              key={answer.uniqueKey}
              type="button"
              className="w-full text-left p-2"
              onClick={() => gradeQuizAnswer(answer)}
            >
              <div
                className={`shadow-md text-black bg-white h-full ${
                  (answer?.wasSelected || answer.isCorrectAnswer) &&
                  isQuizFinished &&
                  (answer.isCorrectAnswer
                    ? "border-2 border-success"
                    : "border-2 border-error")
                }`}
              >
                <img
                  src={answer?.answerImage?.[0].url}
                  alt="Answer option"
                  className="object-cover w-full h-full object-center"
                  onLoad={() => {
                    setLoading(false);
                  }}
                />
              </div>
            </button>
          ))}
        </div>
      </div>
      {loading && <ContentLoader />}
      {showModal && (
        <Modal
          showHeader={false}
          hideModal={hideModal}
          children={getModalBody()}
        />
      )}
    </div>
  );
};

export default ImageQuizContent;
