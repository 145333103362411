import React, { useState, useEffect, useMemo, useContext } from "react";
import { IonContent, IonIcon, IonPage } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import { RouteComponentProps } from "react-router";
import { db, flamelinkApp as flamelink } from "../../firebase";
import { CourseTimerContext } from "../../Providers/CourseTimerProvider";
import { AuthContext } from "../../Providers/UserProvider";
import { msToFormattedTime } from "../../Utils/timeFunctions";
import ImageQuizContent from "./ImageQuizContent";
import MarkdownContent from "./MarkdownContent";
import SectionCollection from "./SectionCollection";
import SlideshowContent from "./SlideshowContent";
import TextQuizContent from "./TextQuizContent";
import VideoContent from "./VideoContent";
import NextContentButton from "../../components/NextContentButton";
import ContentLoader from "../../components/ContentLoader";

const Content: React.FC<
  RouteComponentProps<{ contentId: string; courseId: string }>
> = ({ match, history }) => {
  const { studentId } = useContext(AuthContext);

  const contentId = useMemo(
    () => (match.params ? match.params.contentId : null),
    [match]
  );
  const courseId = useMemo(
    () => (match.params ? match.params.courseId : null),
    [match]
  );

  const { logStartTime, logCheckIn, logStopTimeAndSync, syncTimeLogged } =
    React.useContext(CourseTimerContext);

  useEffect(() => {
    if (courseId && contentId) {
      const interval = setInterval(() => {
        logCheckIn({ contentId });
      }, 7234); // bit of randomness so clocks don't throw always multiples of 7

      (async () => {
        await syncTimeLogged({ courseId, studentId, contentId }); // in context, looks for any time logged saved to storage and writes to FB. that way if someone closes the app abruptly it will be rectified when they come back
        await logStartTime({ contentId });
      })();
      return () => {
        logStopTimeAndSync({ courseId, studentId, contentId }); // sets timeClosed and then syncs with FB. don't await this.
        clearInterval(interval);
      };
    }
  }, [
    logStartTime,
    logCheckIn,
    logStopTimeAndSync,
    syncTimeLogged,
    studentId,
    courseId,
    contentId,
  ]);

  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState([] as any);
  const [isContentCompleted, setIsContentCompleted] = useState(false);
  const [currentContent, setCurrentContent] = useState({} as any);
  const [sections, setSections] = useState([] as any);
  const [animationsCorrect, setAnimationsCorrect] = useState(null as any);
  const [animationsIncorrect, setAnimationsIncorrect] = useState(null as any);
  const [currentStudentCourse, setCurrentStudentCourse] = useState({} as any);
  const [studentContents, setStudentContents] = useState({} as any);

  const [currentStudentContent, setCurrentStudentContent] = useState({} as any);
  const [contentTime, setContentTime] = useState(0);
  const [courseTime, setCourseTime] = useState(0);

  useEffect(() => {
    setCourseTime(currentStudentCourse.aggregateTimeLogged ?? 0);
    setContentTime(currentStudentContent.aggregateTimeLogged ?? 0);
  }, [
    currentStudentContent.aggregateTimeLogged,
    currentStudentCourse.aggregateTimeLogged,
  ]);

  useEffect(() => {
    const courseTimerInterval = setInterval(() => {
      setCourseTime(courseTime + 1000);
      setContentTime(contentTime + 1000);
    }, 1000);

    return () => {
      clearInterval(courseTimerInterval);
    };
  }, [courseTime, contentTime]);

  useEffect(() => {
    setIsContentCompleted(false);
  }, [contentId]);

  const nextContentButtonProps = useMemo(() => {
    if (!courseId || !contentId) {
      return null;
    }

    return {
      studentId,
      courseId,
      contentId,
      currentContent,
      setLoading,
    };
  }, [contentId, currentContent, studentId, courseId]);

  useEffect(() => {
    // get and set student-course data (current content, next content, etc)
    return db
      .doc(`/students/${studentId}/courses/${courseId}`)
      .onSnapshot((doc) => {
        setCurrentStudentCourse({ id: doc.id, ...doc.data() });
      });
  }, [courseId, studentId]);

  useEffect(() => {
    return db
      .doc(`/students/${studentId}/courses/${courseId}/contents/${contentId}`)
      .onSnapshot((doc) => {
        setCurrentStudentContent({ id: doc.id, ...doc.data() });
      });
  }, [studentId, courseId, contentId]);

  useEffect(() => {
    // Get actual content data (e.g. markdown html, quiz questions)
    const getCurrentContent = async () => {
      setLoading(true);
      contentId &&
        flamelink.content
          .get({
            schemaKey: "content",
            fields: [
              "course",
              "contentType",
              "id",
              "section",
              "orderIndex",
              "markdown",
              "video",
              "imageQuiz",
              "textQuiz",
              "title",
              "slideshow",
            ],
            populate: true,
            entryId: contentId,
          })
          .then((content) => {
            setCurrentContent(content);
            setLoading(false);
          });
    };

    getCurrentContent();
  }, [contentId]);

  useEffect(() => {
    // get all student-contents. If the current one (matching URL param) doesn't exist, create it.
    const getStudentContents = async () => {
      // don't set doc until we've loaded data we need
      if (!currentContent || isEmpty(currentContent)) {
        return;
      }
      await db
        .collection(`/students/${studentId}/courses/${courseId}/contents/`)
        .get()
        .then(async (snap) => {
          const studentContentList = snap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setStudentContents(keyBy(studentContentList, "id"));
          if (isEmpty(snap.docs.filter((doc) => doc.id === contentId))) {
            await db
              .doc(
                `/students/${studentId}/courses/${courseId}/contents/${contentId}`
              )
              .set({
                isComplete: false,
                aggregateTimeLogged: 0,
                quizQuestionCorrect: null,
                title: currentContent.title,
              });
          }
        });
    };

    getStudentContents();
  }, [contentId, courseId, studentId, currentContent]);

  useEffect(() => {
    // get information about contents to display in content collection (dropdowns below main content)
    const courseRef = db.doc(`fl_content/${courseId}`);
    flamelink.content.subscribe({
      schemaKey: "content",
      fields: ["course", "contentType", "id", "section", "orderIndex", "title"],
      populate: [
        {
          field: "section",
          fields: ["id"],
        },
      ],
      filters: [["course", "==", courseRef]],
      orderBy: "orderIndex",
      callback: (_, contentList) => {
        setContents(Object.values(contentList ?? {}));
      },
    });
  }, [courseId]);

  useEffect(() => {
    // get information about sections to display in section collection (dropdowns)
    const courseRef = db.doc(`fl_content/${courseId}`);
    flamelink.content.subscribe({
      schemaKey: "section",
      fields: ["id", "course", "title", "orderIndex"],
      filters: [["course", "==", courseRef]],
      orderBy: "orderIndex",
      callback: (error, sectionData) => {
        setSections(Object.values(sectionData ?? {}));
      },
    });
  }, [courseId]);

  // animations
  useEffect(() => {
    flamelink.content.subscribe({
      schemaKey: "animation",
      fields: ["id", "animationForCorrectAnswer", "title", "animationFile"],
      populate: [
        {
          field: "animationFile",
          fields: ["url"],
        },
      ],
      callback: (error, animationData) => {
        const animations = Object.keys(animationData ?? {}).map(
          (key: string) => ({
            id: key,
            ...animationData[key],
          })
        );
        setAnimationsCorrect(
          animations?.filter((animation) => animation.animationForCorrectAnswer)
        );
        setAnimationsIncorrect(
          animations?.filter(
            (animation) => !animation.animationForCorrectAnswer
          )
        );
      },
    });
  }, []);

  // combine student-specific content data (e.g. isComplete) with
  // general content data
  const mergedContentAndSectionData = useMemo(() => {
    return sections.map((section: any) => ({
      contents: contents
        .filter((content: any) => content.section.id === section.id)
        .map((content: any) => {
          content.isActive =
            content.id === contentId ||
            content.id === currentStudentCourse.currentContentId;
          content.isComplete = studentContents[content.id]?.isComplete;
          content.courseId = courseId;
          return content;
        }),
      title: section?.title,
      isSectionActive: !isEmpty(
        contents.filter(
          (content: any) =>
            content?.section?.id === section.id && content.id === contentId
        )
      ),
      id: section.id,
    }));
  }, [
    sections,
    contents,
    contentId,
    studentContents,
    courseId,
    currentStudentCourse,
  ]);

  const isLoading = useMemo(
    () => isEmpty(currentContent) || loading,
    [currentContent, loading]
  );

  const getContent = () => {
    switch (currentContent.contentType) {
      case "video":
        return (
          <VideoContent
            url={currentContent.video[0].url}
            setIsContentCompleted={setIsContentCompleted}
          />
        );
      case "slideshow":
        return (
          <SlideshowContent
            {...currentContent}
            setIsContentCompleted={setIsContentCompleted}
          />
        );
      case "textQuiz":
        return (
          <TextQuizContent
            animationsCorrect={animationsCorrect}
            animationsIncorrect={animationsIncorrect}
            {...currentContent.textQuiz}
            setIsContentCompleted={setIsContentCompleted}
            isComplete={currentStudentContent?.isComplete}
            courseId={courseId}
            contentId={contentId}
          />
        );
      case "imageQuiz":
        return (
          <ImageQuizContent
            animationsCorrect={animationsCorrect}
            animationsIncorrect={animationsIncorrect}
            {...currentContent.imageQuiz}
            setIsContentCompleted={setIsContentCompleted}
            isComplete={currentStudentContent?.isComplete}
            courseId={courseId}
            contentId={contentId}
          />
        );
      case "markdown":
      default:
        return (
          <MarkdownContent
            {...currentContent}
            setIsContentCompleted={setIsContentCompleted}
          />
        );
    }
  };

  return (
    <IonPage>
      <IonContent>
        <div className="p-2 bg-gray-800 flex flex-row items-center text-white shadow-lg">
          <div className="flex flex-col">
            <button
              onClick={() => {
                history.push("/home");
              }}
              className="text-3xl px-2 pr-3 my-auto text-white"
            >
              <IonIcon icon={arrowBack} className="block my-auto" />
            </button>
          </div>
          <div className="flex-1 overflow-hidden overflow-ellipsis whitespace-nowrap">
            <h3 className="text-center tracking-wide overflow-hidden overflow-ellipsis whitespace-nowrap text-md md:text-lg">{`${
              currentContent?.course?.title ?? "Content"
            } - ${currentContent?.title ?? "Content"}`}</h3>
          </div>
          <div className="min-w-0 whitespace-nowrap bg-white px-3 py-1 rounded-lg flex flex-col">
            <div className="text-gray-800 flex flex-row justify-between">
              <span className="mr-1">Course:</span>{" "}
              <span>{msToFormattedTime(courseTime)}</span>
            </div>
            <div className="text-gray-800 flex flex-row justify-between">
              <span className="mr-1">Content:</span>{" "}
              <span> {msToFormattedTime(contentTime)}</span>
            </div>
          </div>
        </div>
        {isLoading ? <ContentLoader /> : getContent()}
        {(currentStudentContent?.isComplete || isContentCompleted) &&
          nextContentButtonProps &&
          !isLoading && <NextContentButton {...nextContentButtonProps} />}
        <div className="bg-white">
          <div className="flex flex-row flex-nowrap overflow-x-auto no-scrollbar">
            <div className="flex-none p-2 px-3 text-center">
              <h2 className="text-gray-800 text-lg font-medium">
                Course Content
              </h2>
            </div>
          </div>
          <div className="divide-y divide-gray-300">
            {mergedContentAndSectionData.map((sectionCol: any) => (
              <SectionCollection {...sectionCol} key={sectionCol.id} />
            ))}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Content;
