interface SecondaryButtonProps {
  text: string;
  textColor?: string;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  text,
  textColor,
}) => {
  return (
    <button
      type="button"
      className={`font-medium bg-white rounded-md px-4 py-1 md:text-lg uppercase shadow-md ${
        textColor ?? "text-success"
      }`}
    >
      {text}
    </button>
  );
};

export default SecondaryButton;
