interface ModalProps {
  showHeader: boolean;
  hideModal?: () => void;
}

const Modal: React.FC<ModalProps> = ({ showHeader, hideModal, children }) => {
  return (
    <div className="fixed inset-0 z-10 bg-opacity-50 bg-black">
      <div className="max-w-3/4 md:max-w-1/2 z-20 bg-white rounded-lg mx-auto mt-32">
        {showHeader && (
          <div className="border-b border-gray-200 text-right">
            <div className="p-2 px-3 pb-1">
              <button
                type="button"
                onClick={hideModal}
                className="ml-auto text-2xl"
              >
                &times;
              </button>
            </div>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
