import React, { useEffect } from "react";
import parse from "html-react-parser";

interface MarkdownContentProps {
  markdown: string;
  setIsContentCompleted: any;
}

const MarkdownContent: React.FC<MarkdownContentProps> = ({
  markdown,
  setIsContentCompleted,
}) => {
  useEffect(() => {
    setIsContentCompleted(true);
  }, [setIsContentCompleted]);

  return (
    <div>
      <div className="p-2 bg-white text-black">{parse(markdown)}</div>
      <hr />
    </div>
  );
};

export default MarkdownContent;
