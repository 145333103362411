import React, { useContext, useState } from "react";
import { functions } from "../../../firebase";
import { AuthContext } from "../../../Providers/UserProvider";
import { showError } from "../../../Services/ui";

const RedeemTokenComponent = () => {
  const [code, setCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { studentId } = useContext(AuthContext);

  const redeemToken = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const register = functions.httpsCallable("redeemCourseToken");
    register({
      code,
      userId: studentId,
    })
      .then(() => {
        setCode("");
        setLoading(false);
        setShowModal(false);
      })
      .catch((e: any) => {
        showError(e);
        setLoading(false);
      });
  };

  return (
    <div>
      <button
        type="button"
        className="px-3 py-1 font-medium text-white bg-indigo-500 hover:bg-indigo-700 rounded-md outline-none focus:outline-none border border-indigo-700"
        onClick={() => setShowModal(true)}
      >
        Redeem token
      </button>
      {showModal && (
        <div className="absolute inset-0 bg-dark-transparent">
          <div className="relative mx-auto max-w-md mt-32 rounded-md bg-white opacity-100 p-4">
            <h2 className="text-lg font-medium text-center">Redeem</h2>
            <p className="text-sm text-gray-600 mb-4">
              Add a course to your homepage by redeeming a token
            </p>
            <div className="absolute top-2 right-2">
              <button
                type="button"
                className="p-2 text-xl text-right"
                onClick={() => setShowModal(false)}
              >
                &times;
              </button>
            </div>
            <form className="text-left">
              <label htmlFor="code" className="text-gray-600">
                Code
                <input
                  type="text"
                  id="code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  className="px-2 py-1 rounded-md border border-gray-200 outline-none focus:outline-none focus:ring-1 focus:ring-blue-500 block w-full"
                  required
                />
              </label>
              <div className="text-center mt-3">
                <button
                  type="submit"
                  className={`px-3 py-1 rounded-md ${
                    !code || loading
                      ? "bg-gray-600 opacity-50"
                      : "bg-blue-500 hover:bg-blue-700"
                  }`}
                  onClick={(e) => redeemToken(e)}
                  disabled={!code || loading}
                >
                  <div className="flex flex-row items-center">
                    {loading && (
                      <div className="rounded-full border-b-2 border-white animate-spin p-2 h-4 w-4 mx-2" />
                    )}
                    <p className="text-white font-semibold">Redeem</p>
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default RedeemTokenComponent;
