const pad = (num: number) => {
  return num.toString().padStart(2, "0");
};

export const msToFormattedTime = (ms: number) => {
  const hours = Math.floor(ms / 1000 / 60 / 60);
  const minutes = Math.floor(ms / 1000 / 60 - hours * 60);
  const seconds = Math.floor(ms / 1000 - hours * 60 * 60 - minutes * 60);
  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};
