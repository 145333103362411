import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import flamelink from "flamelink/app";
import "flamelink/content";
import "flamelink/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDZuxXVCgiv6j8KwCT2odoFVdHrPU1LWjE",
  authDomain: "food-academy-1ab4d.firebaseapp.com",
  projectId: "food-academy-1ab4d",
  storageBucket: "food-academy-1ab4d.appspot.com",
  messagingSenderId: "428196771370",
  appId: "1:428196771370:web:721d09e2f8eb3bcc827553",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const flamelinkApp = flamelink({
  firebaseApp,
  dbType: "cf",
});

export const db = firebase.firestore();
export const auth = firebase.auth();
export const functions = firebase.functions();
export default firebase;
